import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AuthContext from "../../contexts/AuthContext";

import { PERMISSION } from "../../utils/constants";

import "./EmployeeAvailabilityFreeSlotsCalendar.scss";
import translate from "../../utils/translate";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";
import AvailabilityCalendar from "../AvailabilityCalendar";

const EmployeeAvailabilityFreeSlotsCalendar = ({ employee = '', cabinet = '', location = '', startDateKwargName = 'startDate', endDateKwargName = 'endDate' }) => {
  const [events, setEvents] = useState([]);
  const [freeSlots, setFreeSlots] = useState([]);
  const [daysOff, setDaysOff] = useState([]);
  const [freeSlotsTimeout, setFreeSlotsTimeout] = useState(undefined);
  const [daysOffTimeout, setDaysOffTimeout] = useState(undefined);
  const [dateRange, setDateRage] = useState(null);
  const browserLocation = useLocation();
  const history = useHistory();
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canListEmployeeAvailability = hasPermission(PERMISSION.CAN_LIST_EMPLOYEE_AVAILABILITY);

  useEffect(() => {
    if (canListEmployeeAvailability && dateRange) {
      clearTimeout(freeSlotsTimeout);
      setFreeSlotsTimeout(setTimeout(() => apiClient
        .get("api.employeeAvailability.freeSlots", { ...dateRange }, { employee, cabinet, cabinet__location: location })
        .then((res) => setFreeSlots(res.results.reduce((freeSlots, employeeAvailability) => freeSlots.concat(employeeAvailability.free_slots.map(freeSlot => ({
            title: `${employeeAvailability.user.first_name} ${employeeAvailability.user.last_name} (${translate(employeeAvailability.cabinet.location)})`,
            start: freeSlot.start_date,
            end: freeSlot.end_date,
            colorSelectionCallback: (colorPalette) => colorPalette[employeeAvailability.user.id % colorPalette.length],
          }))), [])))
      ));
    }
  }, [employee, cabinet, location, dateRange]);

  useEffect(() => {
    if (canListEmployeeAvailability && dateRange) {
      clearTimeout(daysOffTimeout);
      setDaysOffTimeout(setTimeout(() => apiClient
        .get("api.employeeAvailability.daysOff", { ...dateRange }, { employee, employee__employeeavailability__cabinet: cabinet, employee__employeeavailability__cabinet__location: location })
        .then((res) => setDaysOff(res.results.map((dayOff) => ({
            title: `${dayOff.reason || translate("Day off")} - ${dayOff.user.first_name} ${dayOff.user.last_name}`,
            start: dayOff.start_date,
            end: dayOff.end_date,
          }))))
      ));
    }
  }, [employee, cabinet, location, dateRange]);

  useEffect(() => {
    setEvents(daysOff.concat(freeSlots));
  }, [daysOff, freeSlots]);

  useEffect(() => {
    const startDate = getSearchParamsObject(browserLocation)[startDateKwargName];
    const endDate = getSearchParamsObject(browserLocation)[endDateKwargName];

    if (startDate && endDate && (!dateRange || dateRange.startDate !== startDate || dateRange.endDate !== endDate)) {
      setDateRage({ startDate, endDate });
    }
  }, [browserLocation]);

  const updateDateRangeParam = (value, kwargName) => {
    if (getSearchParamsObject(browserLocation)[kwargName] !== value) {
      updateSearchParams(history, browserLocation, { [kwargName]: value });
    }
  };

  return canListEmployeeAvailability ? (
    <div className="component-employee-availability-free-slots-calendar">
      <AvailabilityCalendar
        dayHeaderFormat={{ weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }}
        datesSet={(dateInfo) => setTimeout(() => {
          updateDateRangeParam(dateInfo.start.toLocaleString('sv').split(' ')[0], startDateKwargName);
          updateDateRangeParam(new Date(dateInfo.end.getTime() - 1).toLocaleString('sv').split(' ')[0], endDateKwargName);
        })}
        events={events}
        allDaySlot={daysOff.filter(dayOff => dayOff.start.length === 10).length > 0}
      />
    </div>
  ) : null;
};

export default EmployeeAvailabilityFreeSlotsCalendar;
