import { useContext, useState } from "react";
import EmployeeAvailabilityFreeSlotsCalendar from "../../components/EmployeeAvailabilityFreeSlotsCalendar";
import EmployeeAvailabilitySelect from "../../components/EmployeeAvailabilitySelect";
import AuthContext from "../../contexts/AuthContext";

import { PERMISSION } from "../../utils/constants";

import "./EmployeeAvailabilityFreeSlots.scss";


const EmployeeAvailabilityFreeSlots = () => {
  const [additionalKwargs, setAdditionalKwargs] = useState({});
  const { hasPermission } = useContext(AuthContext);
  const canListEmployeeAvailability = hasPermission(PERMISSION.CAN_LIST_EMPLOYEE_AVAILABILITY);


  return canListEmployeeAvailability ? (
    <div className="container-employee-availability-free-slots">
      <EmployeeAvailabilitySelect
        setAdditionalKwargs={setAdditionalKwargs}
        additionalKwargs={additionalKwargs}
      />
      <EmployeeAvailabilityFreeSlotsCalendar {...additionalKwargs}/>
    </div>
  ) : null;
};

export default EmployeeAvailabilityFreeSlots;
