export const PERMISSION = Object.freeze({
  IS_COORDINATOR: "calmeo.is_coordinator",
  CAN_MANAGE_INVOICES : "calmeo.can_manage_invoices",
  CAN_DELEGATE_EVENTS: "calmeo.can_delegate_events",
  CAN_MANAGE_PATIENT_ATTACHMENT: "calmeo.can_manage_patient_attachment",
  CAN_SKIP_EVENTS: "calmeo.can_skip_events",
  CAN_MANAGE_TRANSACTIONS: "calmeo.can_manage_transactions",
  CAN_MANAGE_COLLECTED_CASH: "calmeo.can_manage_collected_cash",
  CAN_LIST_EMPLOYEE_AVAILABILITY: "calmeo.can_list_employee_availability",
});

export const PAYMENT_METHOD = Object.freeze({
  CARD: "card",
  CASH: "cash",
  TRANSFER: "transfer",
  STRIPE: "stripe",
});

export const EVENT_STATUS = Object.freeze({
  ACTIVE: "active",
  MISSED_WITH_DUE_PAYMENT: "missed_with_due_payment",
  PAID: "paid",
  SKIPPED: "skipped",
  SKIP_IN_REPORT: "skip_in_report",
  CANCELLED: "cancelled",
});

export const TRANSACTION_STATUS = Object.freeze({
  SKIPPED: "skipped",
  NOT_ASSIGNED: "not_assigned",
  ASSIGNED: "assigned",
  INCORRECTLY_ASSIGNED: "incorrectly_assigned",
});

export const INVOICE_REQUEST_TYPE = Object.freeze({
  NONE: "none",
  FOR_TAX_NUMBER: "for_tax_number",
  FOR_RECEIPT: "for_receipt",
});

export const INVOICING_TYPE = Object.freeze({
  INVOICE_PER_EVENT: "invoice_per_event",
  INVOICE_CUMULATIVE: "invoice_cumulative",
});

export const TRANSACTION_IMPORT_SOURCES = Object.freeze({
  ING: "ing",
  ING_CARD: "ing_card",
  PEP_CARD: "pep_card",
});

export const CONTRACT_TYPE = Object.freeze({
  CONTRACT_OF_MANDATE: "contract_of_mandate",
  B2B_CONTRACT: "b2b",
});

export const LOCATIONS = Object.freeze({
  KATOWICE: "katowice",
  TYCHY: "tychy",
  TYCHY_BIELSKA: "tychy_bielska",
  MYSLOWICE: "myslowice",
  ONLINE: "online",
  ALL_LOCATIONS: "all_locations"
});
