import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { PERMISSION } from "../../utils/constants";
import translate from "../../utils/translate";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";

import "./EmployeeAvailabilitySelect.scss";


const EmployeeAvailabilitySelect = ({
                                      setAdditionalKwargs,
                                      additionalKwargs,
                                      employeeKwargName = 'employee',
                                      cabinetKwargName = 'cabinet',
                                      locationKwargName = 'location'
                                    }) => {
  const [events, setEvents] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedCabinet, setSelectedCabinet] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [availableCabinets, setAvailableCabinets] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canListEmployeeAvailability = hasPermission(PERMISSION.CAN_LIST_EMPLOYEE_AVAILABILITY);

  useEffect(() => {
    if (canListEmployeeAvailability) {
      apiClient
        .get("api.employeeAvailability.list")
        .then((res) => setEvents(res.results));
    }
  }, [apiClient]);

  useEffect(() => {
    setSelectedEmployee(getSearchParamsObject(location)[employeeKwargName] || '');
    setSelectedCabinet(getSearchParamsObject(location)[cabinetKwargName] || '');
    setSelectedLocation(getSearchParamsObject(location)[locationKwargName] || '');
  }, [location]);

  useEffect(() => {
    calculateAvailableOptions();
    setAdditionalKwargs({
      ...additionalKwargs,
      [employeeKwargName]: selectedEmployee,
      [cabinetKwargName]: selectedCabinet,
      [locationKwargName]: selectedLocation,
    });
  }, [events, selectedEmployee, selectedCabinet, selectedLocation]);

  const handleChange = (value, kwargName) => updateSearchParams(history, location, { [kwargName]: value });

  const calculateAvailableOptions = () => {
    const getCabinetSortName = cabinet => `${cabinet.location}__${cabinet.name}`;
    const getEmployeeSortName = employee => `${employee.user.last_name} ${employee.user.first_name}`;

    const availableLocations = events
      .reduce((locations, event) => locations.includes(event.cabinet.location) ? locations : [...locations, event.cabinet.location], [])
      .sort();
    const availableCabinets = events
      .reduce((cabinets, event) => cabinets.map(cabinet => cabinet.id).includes(event.cabinet.id) ? cabinets : [...cabinets, {
        id: event.cabinet.id,
        name: `${event.cabinet.name} (${translate(event.cabinet.location)})`,
        location: event.cabinet.location
      }], [])
      .sort((a, b) => getCabinetSortName(a).localeCompare(getCabinetSortName(b)));
    const availableEmployees = events
      .reduce((employees, event) => employees.map(employee => employee.id).includes(event.employee) ? employees : [...employees, {
        id: event.employee,
        user: event.user,
        locations: events.reduce((locations, innerEvent) => locations.includes(innerEvent.cabinet.location) || event.employee !== innerEvent.employee ? locations : [...locations, innerEvent.cabinet.location], []),
        cabinets: events.reduce((cabinets, innerEvent) => cabinets.includes(innerEvent.cabinet.id) || event.employee !== innerEvent.employee ? cabinets : [...cabinets, innerEvent.cabinet.id], []),
      }], [])
      .sort((a, b) => getEmployeeSortName(a).localeCompare(getEmployeeSortName(b)));

    setAvailableLocations(availableLocations
      .filter(location => selectedCabinet ? availableCabinets.find(availableCabinet => availableCabinet.id === parseInt(selectedCabinet)).location === location : true)
      .filter(location => selectedEmployee ? availableEmployees.find(availableEmployee => availableEmployee.id === parseInt(selectedEmployee)).locations.includes(location) : true));
    setAvailableCabinets(availableCabinets
      .filter(cabinet => selectedLocation ? cabinet.location === selectedLocation : true)
      .filter(cabinet => selectedEmployee ? availableEmployees.find(availableEmployee => availableEmployee.id === parseInt(selectedEmployee)).cabinets.includes(cabinet.id) : true));
    setAvailableEmployees(availableEmployees
      .filter(employee => selectedLocation ? employee.locations.includes(selectedLocation) : true)
      .filter(employee => selectedCabinet ? employee.cabinets.includes(parseInt(selectedCabinet)) : true));
  };

  return events.length > 0 && canListEmployeeAvailability ? (
    <div className="component-employee-availability-select">
      <Form.Control
        as="select"
        onChange={(event) => handleChange(event.target.value, locationKwargName)}
        value={selectedLocation}
      >
        <option value={''}>{translate("Choose location")}</option>
        {availableLocations.map(location => (
          <option
            key={location}
            value={location}
          >{translate(location)}</option>
        ))}
      </Form.Control>

      <Form.Control
        as="select"
        onChange={(event) => handleChange(event.target.value, cabinetKwargName)}
        value={selectedCabinet}
      >
        <option value={''}>{translate("Choose cabinet")}</option>
        {availableCabinets.map(cabinet => (
          <option
            key={cabinet.id}
            value={cabinet.id}
          >{cabinet.name}</option>
        ))}
      </Form.Control>

      <Form.Control
        as="select"
        onChange={(event) => handleChange(event.target.value, employeeKwargName)}
        value={selectedEmployee}
      >
        <option value={''}>{translate("Choose therapist")}</option>
        {availableEmployees.map(employee => (
          <option
            key={employee.id}
            value={employee.id}
          >{`${employee.user.last_name} ${employee.user.first_name}`}</option>
        ))}
      </Form.Control>
    </div>
  ) : null;
};

export default EmployeeAvailabilitySelect;
