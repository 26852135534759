import locale from "@fullcalendar/core/locales/pl";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";

import "./AvailabilityCalendar.scss";

const colorPalette = [
  "#925E78", // Original Color 1
  "#48A9A6", // Original Color 2
  "#CC8B8C", // Original Color 3
  "#779CAB", // Original Color 4
  "#DCAB6B", // Original Color 5
  "#A17A8D", // Dusty Rose
  "#407F7F", // Teal Green
  "#E6A49E", // Pale Coral
  "#B59A9A", // Soft Taupe
  "#D8B26E", // Muted Mustard
  "#5D3A4E", // Deep Plum
  "#317472", // Dark Cyan
  "#CB6A49", // Burnt Sienna
  "#A5A586", // Soft Olive
  "#E2C298", // Sandy Beige
  "#8C8986", // Warm Gray
  "#6F8E9E", // Slate Blue
  "#404040", // Charcoal
  "#D7B98E", // Golden Wheat
  "#0B4553", // Deep Teal
  "#1C512B", // Forest Green
  "#43134B", // Deep Plum
  "#3A342E", // Charcoal Brown
  "#371B19", // Dark Mahogany
  "#4E4D49"  // Smoky Gray
];

const AvailabilityCalendar = ({ ...attributes }) => {
  const events = attributes.events = (attributes.events || []).map((event) => ({ ...event, color: event.colorSelectionCallback ? event.colorSelectionCallback(colorPalette) : 'default' }));

  return (
    <div className="component-availability-calendar">
      <FullCalendar
        plugins={[timeGridPlugin]}
        initialView={Math.min(window.screen.width, window.screen.height) < 768 ? "timeGridDay" : "timeGridWeek"}
        headerToolbar={{
          right: 'timeGridWeek,timeGridDay',
          left: 'prev,next',
        }}
        locale={locale}
        weekNumberCalculation="ISO"
        height="auto"
        hiddenDays={[0]}
        slotMinTime={["08:00:00", ...events.map(event => event.start.toLocaleString('sv').split(' ')[1])].sort()[0]}
        {...attributes}
        events={events}
      />
    </div>
  );
};

export default AvailabilityCalendar;
