import { useContext, useEffect, useState } from "react";

import AuthContext from "../../contexts/AuthContext";

import { PERMISSION } from "../../utils/constants";
import translate from "../../utils/translate";

import "./EmployeeAvailabilityCalendar.scss";
import AvailabilityCalendar from "../AvailabilityCalendar";

const EmployeeAvailabilityCalendar = ({ employee = '', cabinet = '', location = '' }) => {
  const [events, setEvents] = useState([]);
  const [employeeAvailabilityTimeout, setEmployeeAvailabilityTimeout] = useState(undefined);
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canListEmployeeAvailability = hasPermission(PERMISSION.CAN_LIST_EMPLOYEE_AVAILABILITY);

  useEffect(() => {
    if (canListEmployeeAvailability) {
      clearTimeout(employeeAvailabilityTimeout);
      setEmployeeAvailabilityTimeout(setTimeout(() => apiClient
        .get("api.employeeAvailability.list", {}, { employee, cabinet, cabinet__location: location })
        .then((res) => {
          setEvents(res.results.map(employeeAvailability => ({
            title: `${employeeAvailability.cabinet.name} - ${employeeAvailability.user.first_name} ${employeeAvailability.user.last_name} (${translate(employeeAvailability.cabinet.location)})`,
            start: convertToDateThisWeek(employeeAvailability.start_hour, employeeAvailability.week_day),
            end: convertToDateThisWeek(employeeAvailability.end_hour, employeeAvailability.week_day),
            colorSelectionCallback: (colorPalette) => colorPalette[employeeAvailability.user.id % colorPalette.length],
          })))
        }))
      );
    }
  }, [apiClient, employee, cabinet, location]);

  const getTargetDateOfThisWeek = (targetWeekDay) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay() === 0 ? 7 : currentDate.getDay(); // targetWeekDay range is 1 - 7 so currentDay needs to be aligned

    let daysDiff = targetWeekDay - currentDay;

    const targetDate = new Date();
    targetDate.setDate(currentDate.getDate() + daysDiff);
    targetDate.setHours(0, 0, 0, 0);

    return targetDate;
  };

  const convertToDateThisWeek = (hour, targetWeekDay) => {
    const targetDate = getTargetDateOfThisWeek(targetWeekDay);

    const [hours, minutes, seconds] = hour.split(":").map(Number);
    targetDate.setHours(hours, minutes, seconds, 0);

    return targetDate;
  };

  return canListEmployeeAvailability ? (
    <div className="component-employee-availability-calendar">
      <AvailabilityCalendar
        validRange={{
          start: getTargetDateOfThisWeek(1),
          end: getTargetDateOfThisWeek(7)
        }}
        events={events}
        eventOrder="title,start,-duration,allDay"
        allDaySlot={false}
        views={{
          week: {
            dayHeaderFormat: { weekday: 'short' }
          },
          day: {
            dayHeaderFormat: { weekday: 'long' }
          }
        }}
      />
    </div>
  ) : null;
};

export default EmployeeAvailabilityCalendar;
