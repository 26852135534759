import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../config";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";
import DateRangePicker from "../DateRangePicker";
import FullPageLoader from "../FullPageLoader";

import "./DatedItemList.scss";


moment.tz.setDefault(config.timeZone);

const DatedItemList = ({
                         apiUrlPattern,
                         startDateAttribute,
                         endDateAttribute,
                         noItemsError,
                         itemComponent: ItemComponent,
                         componentParams = {},
                         additionalKwargs = null,
                         crossContentComponent = null,
                         filteringToggleLabel = null,
                         filteringCallback = null,
                         hideDateRangePicker = false,
                         noAutoRefresh = false,
                       }) => {
  const [loadingItems, setLoadingItems] = useState(true);
  const [loadingTimeout, setLoadingTimeout] = useState(undefined);
  const [reloadIterator, setReloadIterator] = useState(0);
  const [loadingError, setLoadingError] = useState(null);
  const [items, setItems] = useState([]);
  const [enableFiltering, setEnableFiltering] = useState(false);

  const { apiClient } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    const startDate = moment(getSearchParamsObject(location)[startDateAttribute]);
    const endDate = moment(getSearchParamsObject(location)[endDateAttribute]);
    setStartDate(startDate);
    setEndDate(endDate);
  }, [location]);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    setLoadingItems(true);
    setLoadingError(null);

    clearTimeout(loadingTimeout);
    setLoadingTimeout(setTimeout(() =>
      apiClient
        .get(
          apiUrlPattern,
          {},
          {
            ...(additionalKwargs || {}),
            [startDateAttribute]: formattedStartDate,
            [endDateAttribute]: formattedEndDate,
          }
        )
        // TODO: add support for pagination
        .then((res) => res.results)
        .then((items) => setItems(items))
        .catch((error) => setLoadingError(error))
        .finally(() => setLoadingItems(false)))
    );
  }, [startDate, endDate, additionalKwargs, apiClient, enableFiltering, reloadIterator]);

  useEffect(() => {
    if (noAutoRefresh) {
      return;
    }
    const interval = setInterval(() => {
      setReloadIterator(reloadIterator => reloadIterator + 1);
    }, 3 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const onDatesChange = ({ startDate, endDate }) => {
    const newSearchParams = {};

    if (startDate) {
      newSearchParams[startDateAttribute] = startDate.format("YYYY-MM-DD");
    }

    if (endDate) {
      newSearchParams[endDateAttribute] = endDate.format("YYYY-MM-DD");
    }

    if (!!Object.keys(newSearchParams).length) {
      updateSearchParams(history, location, newSearchParams);
    }
  };

  return loadingItems ? (
    <FullPageLoader/>
  ) : (
    <div className="component-dated-item-list">
      <div className="date-picker-container">
        {!hideDateRangePicker ? (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
          />
        ) : null}
        {filteringCallback && filteringToggleLabel ?
          <Form.Group controlId="FilteringCheckbox">
            <Form.Check
              type="checkbox"
              label={filteringToggleLabel}
              checked={enableFiltering}
              onChange={(event) => setEnableFiltering(event.target.checked)}
            />
          </Form.Group>
          : null}
      </div>
      {crossContentComponent}
      {loadingError ? (
        <Alert variant="danger">
          {loadingError.data.message || translate("Error loading items.")}
        </Alert>
      ) : items.length ? (
        items
          .filter(item => filteringCallback && enableFiltering ? filteringCallback(item) : true)
          .map((item) => <ItemComponent key={item.id} item={item} startDate={startDate}
                                        endDate={endDate} {...componentParams}/>)
      ) : (
        <Alert variant="warning">{noItemsError}</Alert>
      )}
    </div>
  );
};

export default DatedItemList;
